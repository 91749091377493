@import '../../../../scss/theme-bootstrap';

.header-offers-banner-item {
  font-family: $helvetica-font;
  font-size: 12px;
  line-height: $site-banner-mobile-height;
  overflow: hidden;
  text-align: center;
  width: auto;
  height: $site-banner-mobile-height;
  padding: 0 50px;
  @include breakpoint($bp--largest-up) {
    padding: 0;
    line-height: $site-banner-height;
    height: $site-banner-height;
  }
  &__img-container {
    display: inline;
    padding-right: 10px;
  }
  &__offer-text {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    p {
      margin: 0;
      font-size: 12px;
      line-height: 1.17;
    }
  }
}
